import React, { useState } from "react";
// import { Link, NavLink } from "react-router-dom";
import {
  Grid,
  Typography,
  Divider,
  // Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import logo from "../../Assets/DylanLogo-color.png";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: theme.spacing(3, 0),
  },
  logo: {
    maxWidth: "60%",
    marginBottom: "25px",
  },
  copyright: {
    margin: theme.spacing(2, 0),
  },
  links: {
    textTransform: "capitalize",
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

function Footer() {
  const classes = useStyles();
  const [topMenu] = useState([
    {
      target: "/producer",
      name: "Producer",
    },
    {
      target: "/policyholder",
      name: "Policyholder",
    },
    {
      target: "/report-a-claim",
      name: "Report a Claim",
    },
  ]);

  const [mainMenu] = useState([
    {
      target: "/",
      name: "Home",
    },
    {
      target: "/products",
      name: "Products",
    },
    {
      target: "#",
      name: "About",
    },
    {
      target: "#",
      name: "Contact",
    },
  ]);

  const TopMenu = topMenu.map((item) => {
    return (
      // <Grid item xs={12} sm={3}>
      <ListItemLink href={`${item.target}`} key={item.name}>
        <ListItemText primary={item.name} />
      </ListItemLink>
      // <NavLink to={`${item.target}`} key={item.name} className={classes.link}>
      //   <Typography variant="h6" align="right">
      //     {item.name}
      //   </Typography>
      // </NavLink>
      // </Grid>
    );
  });
  const MainMenu = mainMenu.map((item) => {
    return (
      // <Grid item xs={12} sm={2}>
      <ListItemLink href={`${item.target}`} key={item.name}>
        <ListItemText primary={item.name} />
      </ListItemLink>
      // <NavLink to={`${item.target}`} key={item.name} className={classes.link}>
      //   <Typography variant="h5">{item.name}</Typography>
      // </NavLink>
      // </Grid>
    );
  });
  return (
    <>
      <Divider></Divider>
      <Grid container justify="center" className={classes.footer}>
        <Grid item xs={10} md={1}></Grid>
        <Grid item xs={10} md={3} align="center">
          <img src={logo} alt="Dylan Insurance" className={classes.logo} />
        </Grid>
        <Grid item xs={10} md={2}>
          <Typography variant="h6">Links</Typography>

          <List
            component="nav"
            aria-label="secondary mailbox folders"
            className={classes.links}
          >
            {MainMenu}
          </List>
        </Grid>
        <Grid item xs={10} md={2}>
          <Typography variant="h6">Logins</Typography>
          <List component="nav" aria-label="secondary mailbox folders">
            {TopMenu}
          </List>
        </Grid>
        <Grid item xs={10} md={3}>
          <Typography variant="h6">Contact</Typography>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem button>
              <ListItemIcon>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText primary="123 E Main St, Orlando FL, 32806" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <PhoneIcon />
              </ListItemIcon>
              <ListItemText primary="(123) 456-7890" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={10} md={1}></Grid>
        <Grid item xs={12}>
          {" "}
          <Typography align="center" className={classes.copyright}>
            Copyright {new Date().getFullYear()} Dylan Insurance
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
export default Footer;
