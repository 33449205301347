import React from "react";

import { Grid, Typography, Box, Card, CardContent } from "@material-ui/core";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  // Label,
  BarChart,
  Bar,
  // Cell,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  // PieChart,
  // Pie,
  // Sector,
  // Cell,
} from "recharts";

import { makeStyles } from "@material-ui/core/styles";

import Policies from "../../Assets/Policies.json";

import PrTable from "../../Components/PrTable";

import PrHeader from "../../Components/Header/PrHeader";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    "@media (min-width:600px)": {
      margin: theme.spacing(5, 10),
    },
    margin: theme.spacing(1, 2),
  },
  chart: {
    height: 200,
  },
}));

function createData(time, amount) {
  return { time, amount };
}

const Ldata = [
  createData("OCT", 140000),
  createData("NOV", 90000),
  createData("DEC", 70000),
  createData("JAN", 180000),
];

const data = [
  {
    name: "OCT",
    prev: 4000,
    cur: 2400,
    amt: 2400,
  },
  {
    name: "NOV",
    prev: 3000,
    cur: 1398,
    amt: 2210,
  },
  {
    name: "DEC",
    prev: 2000,
    cur: 1600,
    amt: 2290,
  },
  {
    name: "JAN",
    prev: 3780,
    cur: 4508,
    amt: 2000,
  },
];

function PrReports() {
  const classes = useStyles();

  return (
    <PrHeader breadcrumb="REPORTS">
      <Box className={classes.container}>
        {/* <Typography variant="h4">Reports</Typography> */}
        {/* <br></br> */}
        <Grid container spacing="6">
          <Grid item md={4}>
            <Card>
              <CardContent>
                <Typography variant="h2" color="primary">
                  248
                </Typography>
                <Typography variant="h6" color="primary">
                  Total Proposals
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardContent>
                <Typography variant="h2" color="primary">
                  73
                </Typography>
                <Typography variant="h6" color="primary">
                  Total Policies
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              <CardContent>
                <Typography variant="h2" color="primary">
                  23
                </Typography>
                <Typography variant="h6" color="primary">
                  Total Claims
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={8}>
            <Typography variant="h6"> Sales Revenue</Typography>
            <Box className={classes.chart}>
              <ResponsiveContainer>
                <LineChart
                  data={Ldata}
                  width={400}
                  height={400}
                  margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                  }}
                >
                  <XAxis
                    dataKey="time"
                    // stroke={theme.palette.text.secondary}
                  />
                  <YAxis
                  // stroke={theme.palette.text.secondary}
                  >
                    {/* <Label
                      angle={270}
                      position="left"
                      style={{
                        textAnchor: "middle",
                        // fill: theme.palette.text.primary,
                      }}
                    >
                      Sales ($)
                    </Label> */}
                  </YAxis>
                  <Line
                    type="monotone"
                    dataKey="amount"
                    // stroke={theme.palette.primary.main}
                    dot={true}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Grid>
          <Grid item md={4}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="prev" fill="#5893b6" />
                <Bar dataKey="cur" fill="#2B5973" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12}>
            <PrTable data={Policies} type="Policies"></PrTable>
          </Grid>
        </Grid>
      </Box>
    </PrHeader>
  );
}

export default PrReports;
