import React from "react";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import {
  Grid,
  Typography,
  Button,
  Divider,
  Box,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { launchFnol } from "../../util/launchChatbot"
// import Page from "../../Components/Page/Page";
import claimImage from "../../Assets/disaster-image.jpg";

const useStyles = makeStyles((theme) => ({
  disasterImage: {
    backgroundImage: `url(${claimImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: "400px",
  },
  disaster: {
    maxWidth: "500px",
    margin: theme.spacing(10, 0),
  },
  disasterHeadline: {
    margin: theme.spacing(3, 0),
  },
  divider: {
    backgroundColor: theme.palette.primary.light,
    height: "3px",
    margin: theme.spacing(1, 0),
  },
  disasterButton: {
    margin: "40px 30%",
    width: "40%",
    textAlign: "center",
  },
}));

function Claim() {
  const classes = useStyles();
  return (
    <div>
      <Header />
      <Box>
        <Grid item className={classes.disasterImage}></Grid>
        <Grid item container justify="center">
          <Box className={classes.disaster}>
            <Container>
              <Typography
                variant="h2"
                align="center"
                className={classes.disasterHeadline}
              >
                How to report a claim
              </Typography>
              <Divider className={classes.divider}></Divider>
              <Typography>
                On our website our chat bot will walk you through the claim
                process. Alternatively, you can call us at 1-888-123-4567.
              </Typography>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.disasterButton}
                onClick={() => launchFnol("Web")}
              >
                RERORT A CLAIM
              </Button>
            </Container>
          </Box>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
}

export default Claim;
