import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import PhHeader from "../../Components/Header/PhHeader";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    "@media (min-width:600px)": {
      margin: theme.spacing(5, 10),
    },
    margin: theme.spacing(1, 2),
  },
  card: {
    display: "flex",
  },
}));

function PhTraining() {
  const classes = useStyles();
  const [training] = useState([
    {
      image:
        "https://images.unsplash.com/photo-1572120360610-d971b9d7767c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
      title: "Starting a new policy",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1525466760727-1d8be8721154?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
      title: "The 6 steps to any Claim",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
      title: "Understanding reports",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
    {
      image:
        "https://images.unsplash.com/photo-1526948531399-320e7e40f0ca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
      title: "The keys to your policy",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
  ]);

  const blog = training.map((item, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Card className={classes.card} elevation="4">
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={item.image}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {item.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {item.exerpt}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  });

  return (
    <PhHeader breadcrumb="TRAINING">
      <Box className={classes.container}>
        <Typography variant="h4">Getting Started</Typography>
        <br></br>
        <Grid container spacing="3">
          {blog}
        </Grid>
      </Box>
    </PhHeader>
  );
}

export default PhTraining;
