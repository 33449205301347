import React, { useState } from "react";

import {
  Grid,
  Typography,
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { makeStyles } from "@material-ui/core/styles";

import PrHeader from "../../Components/Header/PrHeader";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    "@media (min-width:600px)": {
      margin: theme.spacing(5, 10),
    },
    margin: theme.spacing(1, 2),
  },
}));

function PrQuotes() {
  const classes = useStyles();
  const [training] = useState([
    {
      title: "Homeowners & Dwellings",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
    {
      title: "Condominium Owners",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
    {
      title: "Commercial Property",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
    {
      title: "Coverage Areas",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
    {
      title: "Coverage Types",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
    {
      title: "Coverage Areas",
      exerpt:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim, perferendis ullam. Minus sapiente magnam obcaecati et dignissimos quos sequi debitis sint velit saepe rerum, nemo nostrum repudiandae adipisci veniam iusto.",
    },
  ]);

  const blog = training.map((item, index) => {
    return (
      <Grid item xs={12} key={index}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.exerpt}</Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  });

  return (
    <PrHeader breadcrumb="QUOTES">
      <Box className={classes.container}>
        <Grid container justify="flex-end" spacing="3">
          <Grid item xs={12}>
            <Typography variant="h5">
              Fill in the items below to calculate a quote
            </Typography>
          </Grid>
          {blog}
          <Grid item>
            <Button variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </PrHeader>
  );
}

export default PrQuotes;
