import React, { useEffect, useState, useContext } from "react";

// import { NavLink } from "react-router-dom";
import { Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Claims from "../../Assets/claims.json";

import PrTable from "../../Components/PrTable";

import PrHeader from "../../Components/Header/PrHeader";

import { AuthContext } from "../../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    "@media (min-width:600px)": {
      margin: theme.spacing(5, 10),
    },
    margin: theme.spacing(1, 2),
  },
}));

function PrClaims(props) {
  const classes = useStyles();

  const [state] = useContext(AuthContext);
  console.log(state);
  const [claims, setClaims] = useState();

  console.log(state);

  useEffect(() => {
    console.log(state);
    const filtered = Claims.filter((item) => {
      if (state.accType === "agent") {
        if (item.csr) {
          return item;
        }
      } else {
        return item;
      }
      return "";
    });
    setClaims(filtered);
  }, [setClaims, state]);

  return (
    <PrHeader breadcrumb="claims">
      <Box className={classes.container}>
        {claims ? <PrTable data={claims} type="Claims"></PrTable> : null}
      </Box>
    </PrHeader>
  );
}

export default PrClaims;
