import React, { useContext, useEffect, useState } from "react";

import { Grid, Typography, Box } from "@material-ui/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";

import Policies from "../../Assets/Policies.json";
import PrTable from "../../Components/PrTable";
import PrHeader from "../../Components/Header/PrHeader";

import { AuthContext } from "../../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    "@media (min-width:600px)": {
      margin: theme.spacing(5, 10),
    },
    margin: theme.spacing(1, 2),
  },
  policies: {
    margin: theme.spacing(5, 0, 0, 0),
  },
  title: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  chart: {
    height: 200,
  },
}));

function createData(time, amount) {
  return { time, amount };
}

const Ldata = [
  createData("00:00", 0),
  createData("03:00", 300),
  createData("06:00", 600),
  createData("09:00", 1500),
  createData("12:00", 1200),
  createData("15:00", 1300),
  createData("18:00", 900),
  createData("21:00", 700),
  createData("24:00", undefined),
];

const Pdata = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#2B5973", "#437b9c", "#5893b6", "#84b4d0"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function PrDashboard() {
  const classes = useStyles();
  const [state] = useContext(AuthContext);
  console.log(state);
  const [policies, setPolicies] = useState();

  console.log(state);

  useEffect(() => {
    console.log(state);
    const filtered = Policies.filter((item) => {
      if (state.accType === "agent") {
        if (item.csr) {
          return item;
        }
      } else {
        return item;
      }
      return "";
    });
    setPolicies(filtered);
  }, [setPolicies, state]);
  return (
    <PrHeader title="dashboard">
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h1" color="primary" className={classes.title}>
              {state.accType === "csr"
                ? `Welcome ${state.fname} ${state.lname}`
                : "Your Agent Dashboard"}
            </Typography>
            {state.accType === "csr" ? null : (
              <Box>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="h6" align="center" color="primary">
                      Trailing 12 Premiums
                    </Typography>
                    <Box className={classes.chart}>
                      <ResponsiveContainer>
                        <LineChart
                          data={Ldata}
                          width={400}
                          height={400}
                          margin={{
                            top: 16,
                            right: 16,
                            bottom: 0,
                            left: 24,
                          }}
                        >
                          <XAxis
                            dataKey="time"
                            // stroke={theme.palette.text.secondary}
                          />
                          <YAxis
                          // stroke={theme.palette.text.secondary}
                          >
                            <Label
                              angle={270}
                              position="left"
                              style={{
                                textAnchor: "middle",
                                // fill: theme.palette.text.primary,
                              }}
                            >
                              Sales ($)
                            </Label>
                          </YAxis>
                          <Line
                            type="monotone"
                            dataKey="amount"
                            // stroke={theme.palette.primary.main}
                            dot={false}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" align="center" color="primary">
                      Policy Beakdown
                    </Typography>
                    <Box className={classes.chart}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            data={Pdata}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                          >
                            {Pdata.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box className={classes.policies}>
              <Typography variant="h2">Policies</Typography>
              {policies ? <PrTable data={policies} type="Policies" /> : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PrHeader>
  );
}

export default PrDashboard;
