import React, { useEffect, useState, useMemo, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { launchAgentFnol } from "../../util/launchChatbot";

import Policies from "../../Assets/Policies.json";
import Claims from "../../Assets/claims.json";
import PrHeader from "../../Components/Header/PrHeader";
import TabPanel from "../../Components/TabPanel";
import PrTable from "../../Components/PrTable";
import { AuthContext } from "../../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  policyInfo: {
    marginTop: "40px",
  },
  info: {
    backgroundColor: theme.palette.primary.light,
    padding: "10px 20px",
    // maxWidth: "90%",
    color: "white",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    "& p, & h6": {
      alignSelf: "center",
      margin: "0px 6px 0 0",
    },
  },
  appbar: {
    marginTop: "10px",
  },
  policy: {
    width: "100%",
    textAlign: "center",
  },
  billing: {
    margin: theme.spacing(3, 0, 0, 0),
  },
  styledButton: {
    margin: theme.spacing(3, 0, 3, 3),
  },
  textButton: {
    margin: theme.spacing(1, 0, 0, 0),
  },
}));

function PrPolicy() {
  const classes = useStyles();
  const params = useParams();
  const loca = useLocation();
  const [policy, setPolicy] = useState();
  const [claims, setClaims] = useState();
  const [auth] = useContext(AuthContext);
  const [value, setValue] = React.useState(
    loca.pathname.endsWith("billing") ? 0 : 1
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const filtered = Policies.find((item) => item.policy === params.id);
    const filtClaims = Claims.filter((item) => {
      if (item.id === filtered.claims[0].id) {
        console.log(item);
        console.log(filtered);
        return item;
      }

      return "";
    });
    setPolicy(filtered);
    setClaims(filtClaims);
    console.log(filtClaims);
  }, [setPolicy, setClaims, params.id]);

  const date = useMemo(() => {
    if (!policy) {
      return "";
    }
    return `${policy.date}/${new Date().getFullYear()}`;
  }, [policy]);

  return (
    <PrHeader
      policy={policy ? policy : null}
      breadcrumb={value === 1 ? "BILLING" : "CLAIMS"}
    >
      {policy ? (
        <>
          <Box>
            <Button variant="outlined" className={classes.styledButton}>
              APPLICATION FORMS
            </Button>
            <Button variant="outlined" className={classes.styledButton}>
              Change Insurance Prefferences
            </Button>
            <Button 
              variant="outlined" 
              className={classes.styledButton}
              onClick={() => {
                if(auth.accType === "csr") launchAgentFnol(policy.policy, "Internal")
                else launchAgentFnol(policy.policy)
              }}
            >
              Submit A Claim 
            </Button>
            <Grid container className={classes.policyInfo}>
              <Grid
                item
                container
                // justify="center"
                alignContent="center"
                xs={2}
              >
                <Typography variant="body1" className={classes.policy}>
                  {policy.policy}
                </Typography>
                <Typography variant="h6" className={classes.policy}>
                  SUBMITTED
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Box className={classes.info}>
                  <Typography variant="h6">{policy.name}</Typography>
                  <Typography>Date: </Typography>
                  <Typography variant="h6">{date}</Typography>
                  <Typography>Address: </Typography>
                  <Typography variant="h6">
                    {policy.address.line1}, {policy.address.city}{" "}
                    {policy.address.state}, {policy.address.zip}
                  </Typography>
                  <Typography>County: </Typography>
                  <Typography variant="h6">{policy.address.county}</Typography>
                  <Typography>Product: </Typography>
                  <Typography variant="h6">{policy.product}</Typography>
                  <Typography>Company: </Typography>
                  <Typography variant="h6">{policy.company}</Typography>
                  <Typography>Co-Insured: </Typography>
                  <Typography variant="h6">{policy.coInsured}</Typography>
                  <Typography>Years Renewed: </Typography>
                  <Typography variant="h6">{policy.yearsRenewed}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={9}>
                <Box className={classes.appbar}>
                  <AppBar position="static" color="transparent">
                    <Tabs value={value} onChange={handleChange}>
                      <Tab label="Billing"></Tab>
                      <Tab label="Claims"></Tab>
                    </Tabs>
                  </AppBar>

                  <TabPanel value={value} index={1} dir="ltr">
                    <Grid container>
                      <Grid item xs={4} className={classes.billing}>
                        <Typography variant="h6">PAYMENT STATUS</Typography>
                        <Typography variant="h5">PAYMENT PENDING</Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.billing}>
                        <Typography variant="h6">BILL TYPE</Typography>
                        <Typography variant="h5">DIRECT BILL</Typography>
                      </Grid>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={6} className={classes.billing}>
                        <Typography variant="h6">PAYMENT METHOD</Typography>
                        <Typography variant="body1">
                          Policy is setup to accept one-time payments only
                        </Typography>
                        <Button
                          variant="outlined"
                          className={classes.textButton}
                        >
                          {" "}
                          CHANGE PREFERENCES
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={0} dir="ltr">
                    <PrTable data={claims} type="Claims"></PrTable>
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}
    </PrHeader>
  );
}

export default PrPolicy;
