import React, { useEffect, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  // StepContent,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import { makeStyles } from "@material-ui/core/styles";
import Policies from "../../Assets/Policies.json";
import Claims from "../../Assets/claims.json";
import PhHeader from "../../Components/Header/PhHeader";

const useStyles = makeStyles((theme) => ({
  policy: {
    padding: "8px 16px 8px 16px",
  },
  policyInfo: {
    margin: theme.spacing(6, 0, 0, 0),
  },
  info: {
    backgroundColor: theme.palette.primary.light,
    padding: "10px 20px",
    maxWidth: "90%",
    color: "white",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    "& p, & h6": {
      alignSelf: "center",
      margin: "0px 6px 0 0",
    },
  },
  button: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  title: {
    margin: theme.spacing(4, 0, -3, 0),
  },
  stepper: {
    margin: theme.spacing(8, 0, 0, 0),
  },
}));

function PhClaim() {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [claim, setClaim] = useState();

  useEffect(() => {
    const filtered = Claims.find((item) => item.id === params.id);
    console.log(filtered);

    setActiveStep(filtered.status);
    setClaim(filtered);
  }, [params.id]);
  const owner = useMemo(() => {
    if (!claim) {
      return "";
    }
    let filtOwner = Policies.find((policy) => policy.id === claim.owner);
    return filtOwner;
  }, [claim]);

  const getSteps = () => {
    return [
      { label: "Claim Reported", content: "Some content" },
      {
        label: "Internal Adjuster Assigned",
        content: "some really long content that's kinda anoying",
      },
      { label: "Field Adjuster Assigned", content: "" },
      { label: "Inspection Complete", content: "" },
      { label: "Field Report Complete", content: "" },
      { label: "Coverage Determination Made", content: "" },
    ];
  };
  const steps = getSteps();

  return (
    <PhHeader claim={claim ? claim : null} breadcrumb="CLAIM">
      {claim ? (
        <>
          <Box className={classes.policy}>
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
              className={classes.button}
            >
              Back
            </Button>

            <Grid container className={classes.policyInfo}>
              <Grid
                item
                container
                justify="center"
                alignContent="center"
                xs={12}
              >
                <Grid item xs={8}>
                  {/* <Box>
                    <Typography variant="h6">Policy Details: </Typography>
                  </Box> */}
                  <Box className={classes.info}>
                    {owner && owner.id ? (
                      <>
                        <Typography variant="h5">{owner.name}</Typography>
                        <Typography>Date: </Typography>
                        <Typography variant="h5">
                          {claim.date}
                          {/* {new Date(policy.date).getMonth() + 1} /{" "}
                    {new Date(policy.date).getDate()} /{" "}
                    {new Date(policy.date).getFullYear()} */}
                        </Typography>
                        <Typography>Address: </Typography>
                        <Typography variant="h5">
                          {owner.address.line1}, {owner.address.city}{" "}
                          {owner.address.state}, {owner.address.zip}
                        </Typography>
                        <Typography>County: </Typography>
                        <Typography variant="h5">
                          {owner.address.county}
                        </Typography>
                        <Typography>Product: </Typography>
                        <Typography variant="h5">{owner.product}</Typography>
                        <Typography>Company: </Typography>
                        <Typography variant="h5">{owner.company}</Typography>
                        <Typography>Co-Insured: </Typography>
                        <Typography variant="hz">{owner.coInsured}</Typography>
                        <Typography>Years Renewed: </Typography>
                        <Typography variant="h6">
                          {owner.yearsRenewed}
                        </Typography>
                      </>
                    ) : null}
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="h6" align="left">
                    Claim #:
                  </Typography>
                  <Typography>{claim.id}</Typography>
                  <Typography variant="h6" align="left">
                    Claim Status:{" "}
                  </Typography>
                  <Typography>
                    {!claim.closedDate ? "CLOSED" : "OPEN"}
                  </Typography>
                  <Typography variant="h6">Loss Details</Typography>
                  <Typography>{claim.lossType}</Typography>
                  <Typography>{claim.lossDesc}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  color="primary"
                  className={classes.title}
                >
                  Status of Your Claim:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {/* <Typography>The current state of your claim.</Typography> */}
                <Stepper
                  activeStep={activeStep}
                  alternativeLabel
                  className={classes.stepper}
                >
                  {steps.map((step) => (
                    <Step key={step.label}>
                      <StepLabel>
                        <>
                          {step.label === "Claim Reported" ? (
                            <AssignmentIcon
                              style={{ fontSize: 45 }}
                            ></AssignmentIcon>
                          ) : null}
                          {step.label === "Internal Adjuster Assigned" ? (
                            <ContactPhoneIcon
                              style={{ fontSize: 45 }}
                            ></ContactPhoneIcon>
                          ) : null}
                          {step.label === "Field Adjuster Assigned" ? (
                            <PersonPinIcon
                              style={{ fontSize: 45 }}
                            ></PersonPinIcon>
                          ) : null}
                          {step.label === "Inspection Complete" ? (
                            <FindInPageIcon
                              style={{ fontSize: 45 }}
                            ></FindInPageIcon>
                          ) : null}
                          {step.label === "Field Report Complete" ? (
                            <DescriptionIcon
                              style={{ fontSize: 45 }}
                            ></DescriptionIcon>
                          ) : null}
                          {step.label === "Coverage Determination Made" ? (
                            <AssignmentTurnedInIcon
                              style={{ fontSize: 45 }}
                            ></AssignmentTurnedInIcon>
                          ) : null}
                          <Typography variant="h5">{step.label}</Typography>
                          {/* <Typography variant="body1">
                            {step.content}
                          </Typography> */}
                        </>
                      </StepLabel>
                      {/* <StepContent>
                        <Typography>{step.content}</Typography>
                      </StepContent> */}
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}
    </PhHeader>
  );
}

export default PhClaim;
