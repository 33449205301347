import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
export const theme = createMuiTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      light: "#2B5973",
      main: "#0C5466",
      dark: "#0D3A53",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fafafa",
      main: "#C6C6C6",
      dark: "#A5A5A5",
      contrastText: "#0C5466",
    },
    warning: {
      light: "#FF5A50",
      main: "#EF4136",
      dark: "#C72A20",
      contrastText: "#0C5466",
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial"',
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontFamily: '"Montserrat"',
      fontWeight: 800,
      fontSize: "3.25rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
    },
    h2: {
      fontFamily: '"Montserrat"',
      fontWeight: 800,
      fontSize: "2.5rem",
      lineHeight: 1.2,
      letterSpacing: "-0.00833em",
    },
    h3: {
      fontFamily: '"Montserrat"',
      fontWeight: 400,
      fontSize: "2.5rem",
      lineHeight: 1.2,
      letterSpacing: "0em",
    },
    h4: {
      fontFamily: '"Montserrat"',
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h5: {
      fontFamily: '"Montserrat"',
      fontWeight: 800,
      fontSize: "1.2rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    h6: {
      fontFamily: '"Montserrat"',
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
    },
    body: {
      fontFamily: '"Montserrat"',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
  },
  shape: {
    borderRadius: 0,
  },
});
export default responsiveFontSizes(theme);
