import React, { useState, useContext, useEffect } from "react";

// import { NavLink } from "react-router-dom";
import {
  //   Button,
  Box,
  //   InputBase,
  //   IconButton,
  //   Table,
  //   TableBody,
  //   TableCell,
  //   TableContainer,
  //   TableHead,
  //   TableRow,
} from "@material-ui/core";

// import SearchIcon from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";
import Policies from "../../Assets/Policies.json";

import PrTable from "../../Components/PrTable";
import PrHeader from "../../Components/Header/PrHeader";

import { AuthContext } from "../../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    "@media (min-width:600px)": {
      margin: theme.spacing(5, 10),
    },
    margin: theme.spacing(1, 2),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.light,
  },
}));

function PrPolicies() {
  const classes = useStyles();
  const [state] = useContext(AuthContext);
  console.log(state);
  const [policies, setPolicies] = useState();

  console.log(state);

  useEffect(() => {
    console.log(state);
    const filtered = Policies.filter((item) => {
      if (state.accType === "agent") {
        if (item.csr) {
          return item;
        }
      } else {
        return item;
      }
      return "";
    });
    setPolicies(filtered);
  }, [setPolicies, state]);

  return (
    <PrHeader breadcrumb="policies">
      <Box className={classes.container}>
        {policies ? <PrTable data={policies} type="Policies" /> : null}
      </Box>
    </PrHeader>
  );
}

export default PrPolicies;
