import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";

import { AuthContextProvider } from "./Context/AuthContext";

import "./App.css";
import theme from "./Theme/Theme.js";

// Main site imports
import Home from "./Pages/MainSite/Home.js";
import Products from "./Pages/MainSite/Products";
import ReportAClaim from "./Pages/MainSite/Claim.js";
import Login from "./Pages/MainSite/Login";

// Policyholder imports
import PhDashboard from "./Pages/Policyholder/PhDashboard";
import PhPolicy from "./Pages/Policyholder/PhPolicy";
import PhTraining from "./Pages/Policyholder/PhTraining";
import PhClaim from "./Pages/Policyholder/PhClaim";
import PhClaims from "./Pages/Policyholder/PhClaims";

// Producer
import PrDashboard from "./Pages/Producer/PrDashboard";
import PrPolicies from "./Pages/Producer/PrPolicies";
import PrPolicy from "./Pages/Producer/PrPolicy";
import PrClaims from "./Pages/Producer/PrClaims";
import PrClaim from "./Pages/Producer/PrClaim";
import PrQuotes from "./Pages/Producer/PrQuotes";
import PrReports from "./Pages/Producer/PrReports";
import PrTraining from "./Pages/Producer/PrTraining";

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     minHeight: '100vh'
//   },
// }));

function App() {
  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <main>
            <Switch>
              {/* Main Site Pages */}
              <Route path="/" component={Home} exact />
              <Route path="/products" component={Products} />
              <Route path="/report-a-claim" component={ReportAClaim} />

              {/* Policy Holder Pages */}
              <Route path="/policyholder" component={Login} exact />
              <Route path="/policyholder/home" component={PhDashboard} />
              <Route path="/policyholder/policy/:id" component={PhPolicy} />
              <Route path="/policyholder/claims" component={PhClaims} />
              <Route path="/policyholder/claim/:id" component={PhClaim} />
              <Route path="/policyholder/training" component={PhTraining} />

              {/* Producer Pages */}
              <Route path="/producer" component={Login} exact />
              <Route path="/producer/home" component={PrDashboard} />
              <Route path="/producer/policies" component={PrPolicies} />
              <Route path="/producer/policy/:id" component={PrPolicy} />
              <Route path="/producer/claims" component={PrClaims} />
              <Route path="/producer/claim/:id" component={PrClaim} />
              <Route path="/producer/quotes" component={PrQuotes} />
              <Route path="/producer/reports" component={PrReports} />
              <Route path="/producer/training" component={PrTraining} />
            </Switch>
          </main>
        </Router>
      </ThemeProvider>
    </AuthContextProvider>
  );
}

export default App;
