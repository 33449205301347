import React, { useState, useEffect, useContext } from "react";
// import { useParams } from "react-router-dom";

import {
  Grid,
  Typography,
  // Button,
  Card,
  CardContent,
  Box,
  // AppBar,
  // Tabs,
  // Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Policies from "../../Assets/Policies.json";
import Claims from "../../Assets/claims.json";
import PhTable from "../../Components/PhTable";
// import TabPanel from "../../Components/TabPanel";
import PhHeader from "../../Components/Header/PhHeader";

import { AuthContext } from "../../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    "@media (min-width:600px)": {
      margin: theme.spacing(5, 10),
    },
    margin: theme.spacing(1, 2),
  },
  policies: {
    margin: theme.spacing(5, 0, 0, 0),
  },
  title: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  chart: {
    height: 200,
  },
}));

function PhDashboard() {
  const classes = useStyles();
  // const params = useParams();
  const [policies, setPolicies] = useState();
  // const [claims, setClaims] = useState();

  const [state] = useContext(AuthContext);
  // console.log(state);

  useEffect(() => {
    // console.log(state);
    const filtered = Policies.filter((item) => {
      if (item.id === state.userID) {
        return item;
      }
      return "";
    });
    const filtClaims = Claims.filter((item) => {
      if (item.owner === state.userID) {
        console.log(item);
        console.log(filtered);
        return item;
      }

      return "";
    });
    setPolicies(filtered);
    // setClaims(filtClaims);
    console.log(filtClaims, filtered);
  }, [setPolicies, state.userID]);

  // const date = useMemo(() => {
  //   if (!policy) {
  //     return "";
  //   }
  //   return `${policy.date}/${new Date().getFullYear()}`;
  // }, [policy]);

  return (
    <PhHeader title="dashboard">
      <Box className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.title}>
              <Typography variant="h1" color="primary">
                HI {`${state.fname} ${state.lname}`},
              </Typography>
              <Typography variant="h4" color="primary">
                Here's your dashboard.
              </Typography>
            </Box>
            <Grid container spacing="6">
              <Grid item md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h2" color="primary">
                      $748
                    </Typography>
                    <Typography variant="h6" color="primary">
                      {" "}
                      Your Premium
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h2" color="primary">
                      1
                    </Typography>
                    <Typography variant="h6" color="primary">
                      Total Claims
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h2" color="primary">
                      3
                    </Typography>
                    <Typography variant="h6" color="primary">
                      Years of Coverage
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Box className={classes.policies}>
              <Typography variant="h6">Your Policies</Typography>
              {policies ? <PhTable data={policies} type="Policies" /> : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PhHeader>
  );
}

export default PhDashboard;
