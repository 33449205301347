import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { NavLink } from "react-router-dom";
import { Grid, Button, Typography, Box, Divider } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import logo from "../../Assets/DylanLogo.png";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { launchAgentFaq } from "../../util/launchChatbot";
import { AuthContext } from "../../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.primary.light,
    height: "100vh",
    "@media (min-width:600px)": {
      padding: theme.spacing(7),
    },
    padding: theme.spacing(7),
  },
  content: {
    height: "100vh",
    overflowY: "scroll",
  },
  // breadcrumbs: {
  //   width: "100%",
  //   padding: "8px 16px 12px 16px",
  //   color: theme.palette.secondary.light,
  //   backgroundColor: theme.palette.primary.light,
  // },
  breadcrumbsCont: {
    padding: "8px 16px 8px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  policy: {
    padding: "8px 16px 8px 16px",
  },
  breadcrumbs: {
    display: "flex",
    "& h6, & svg": {
      alignSelf: "center",
    },
  },
  logout: {
    textAlign: "right",
  },
  menu: {
    // flexDirection: "row",
  },
  link: {
    color: theme.palette.secondary.light,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  linkType: {
    padding: "15px 0",
    color: theme.palette.secondary.light,
  },
  logo: {
    width: "100%",
    marginBottom: "30px",
  },
  divider: {
    backgroundColor: theme.palette.primary.dark,
    height: "3px",
  },
  chat: {
    alignSelf: "flex-end",
  },
  chatNow: {
    // marginTop: "75%",
    backgroundColor: theme.palette.primary.dark,
    height: "3px",
  },
}));

function PrHeader(props) {
  const history = useHistory();
  console.log(history.location.pathname);
  const classes = useStyles();
  const [auth] = useContext(AuthContext);
  const [mainMenu] = useState([
    {
      target: "/producer/home",
      name: "HOME",
    },
    {
      target: "/producer/policies",
      name: "POLICIES",
    },
    {
      target: "/producer/quotes",
      name: "QUOTES",
    },
    {
      target: "/producer/claims",
      name: "CLAIMS",
    },
    {
      target: "/producer/reports",
      name: "REPORTS",
    },
    {
      target: "/producer/training",
      name: "TRAINING",
    },
  ]);
  const menu = mainMenu.map((item, index) => {
    return (
      <Grid item xs={12} key={index}>
        <NavLink to={`${item.target}`} className={classes.link}>
          <Typography variant="h5" className={classes.linkType}>
            {item.name}
          </Typography>
          <Divider className={classes.divider}></Divider>
        </NavLink>
      </Grid>
    );
  });
  return (
    <Box>
      <Grid container>
        <Grid
          item
          container
          md={3}
          xs={12}
          className={classes.sidebar}
          justify="center"
        >
          <>
            <Grid item>
              <img src={logo} alt="Dylan Insurance" className={classes.logo} />
            </Grid>

            <Grid
              item
              container
              alignContent="flex-start"
              className={classes.menu}
            >
              {/* <Divider className={classes.divider}></Divider> */}
              {menu}
            </Grid>
            <Grid item xs={12} className={classes.chat}>
              <Divider className={classes.chatNow}></Divider>
              <Button fullWidth onClick={() => {
                if(auth.accType === "csr") launchAgentFaq("Internal")
                else launchAgentFaq()
              }}>
                {" "}
                <Typography variant="h5" className={classes.linkType}>
                  CHAT NOW
                </Typography>
              </Button>
            </Grid>
          </>
        </Grid>
        <Grid item md={9} xs={12} className={classes.content}>
          {props.policy ? (
            <Box className={classes.policy}>
              <Typography variant="h5">
                POLICY: {props.policy.policy}
              </Typography>
            </Box>
          ) : null}
          <Box className={classes.breadcrumbsCont}>
            {" "}
            <Grid container>
              <Grid item xs={5} className={classes.breadcrumbs}>
                <Typography variant="h6">HOME </Typography>
                {props.breadcrumb === "policies" ? (
                  <>
                    <ChevronRightIcon />{" "}
                    <Typography variant="h6">POLICIES</Typography>{" "}
                  </>
                ) : null}
                {props.policy ? (
                  <>
                    <ChevronRightIcon />{" "}
                    <Typography variant="h6">{props.policy.name}</Typography>{" "}
                    <ChevronRightIcon />
                    <Typography variant="h6">{props.breadcrumb}</Typography>
                  </>
                ) : null}
                {props.breadcrumb === "claims" ? (
                  <>
                    <ChevronRightIcon />{" "}
                    <Typography variant="h6">CLAIMS</Typography>{" "}
                  </>
                ) : null}
                {props.claim ? (
                  <>
                    <ChevronRightIcon />{" "}
                    <Typography variant="h6">{props.claim.policy}</Typography>{" "}
                    <ChevronRightIcon />
                    <Typography variant="h6">{props.breadcrumb}</Typography>
                  </>
                ) : (
                  <>
                    {/* <ChevronRightIcon />{" "}
                    <Typography variant="h6">{props.breadcrumb}</Typography>{" "} */}
                  </>
                )}
                {/* <Typography variant="h6">HOME </Typography>
                <ChevronRightIcon />{" "}
                <Typography variant="h6">TESTROTHWELL</Typography>{" "}
                <ChevronRightIcon />
                <Typography variant="h6">POLICY</Typography> */}
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={4} className={classes.logout}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    localStorage.clear();
                    history.push("/producer");
                  }}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          </Box>
          {props.children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PrHeader;
