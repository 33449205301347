import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Typography, IconButton, Box, Drawer } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import logo from "../../Assets/DylanLogo.png";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  nav: {
    backgroundColor: theme.palette.primary.main,
    // height: "125px",
    padding: theme.spacing(4, 7),
  },
  logo: {
    height: "75px",
  },
  link: {
    color: theme.palette.secondary.light,
    textDecoration: "none",
    transition: ".2s",
    margin: "0 0 0 35px",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      padding: theme.spacing(5),
      width: "200px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  drawerCont: {
    height: "100%",
  },
  navCont: {
    "@media (min-width:600px)": {
      display: "flex",
    },
    // display: "block",
  },
  mainMenu: {
    textAlign: "center",
  },
  ham: {
    color: "white",
    "@media (min-width:600px)": {
      display: "none",
    },
    display: "block",
    // fontSize: "30px",
  },
  box: {
    // display: "flex",
    "@media (min-width:600px)": {
      display: "flex",
    },
    display: "none",
    margin: theme.spacing(1),
  },
}));

function Header(props) {
  const classes = useStyles();
  const [drawer, setDrawer] = React.useState(false);
  const [topMenu] = useState([
    {
      target: "/producer",
      name: "Producer",
    },
    {
      target: "/policyholder",
      name: "Policyholder",
    },
    {
      target: "/report-a-claim",
      name: "Report a Claim",
    },
  ]);

  const [mainMenu] = useState([
    {
      target: "/",
      name: "HOME",
    },
    {
      target: "/products",
      name: "PRODUCTS",
    },
    {
      target: "#",
      name: "ABOUT",
    },
    {
      target: "#",
      name: "CONTACT",
    },
  ]);

  const TopMenu = topMenu.map((item) => {
    return (
      // <Grid item xs={12} sm={3}>
      <NavLink to={`${item.target}`} key={item.name} className={classes.link}>
        <Typography variant="h6" align="right">
          {item.name}
        </Typography>
      </NavLink>
      // </Grid>
    );
  });
  const MainMenu = mainMenu.map((item) => {
    return (
      // <Grid item xs={12} sm={2}>
      <NavLink to={`${item.target}`} key={item.name} className={classes.link}>
        <Typography variant="h5" align={drawer ? "center" : "right"}>
          {item.name}
        </Typography>
      </NavLink>
      // </Grid>
    );
  });
  return (
    <Grid
      item
      container
      justify="flex-end"
      alignContent="center"
      className={classes.nav}
    >
      <Grid item xs={6}>
        {props.home ? null : (
          <img src={logo} alt="Dylan Insurance" className={classes.logo} />
        )}
      </Grid>
      {/* <Grid item xs={3}></Grid> */}
      <Grid
        container
        item
        justify="flex-end"
        alignContent="center"
        xs={6}
        className={classes.navCont}
      >
        <Box>
          <IconButton
            onClick={() => {
              setDrawer(!drawer);
            }}
            className={classes.ham}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box className={classes.box}>{TopMenu}</Box>
        <Box className={classes.box}>{MainMenu}</Box>
      </Grid>
      <Grid item xs={12}>
        <Drawer
          anchor="left"
          open={drawer}
          onClose={() => {
            setDrawer(!drawer);
          }}
          className={classes.drawer}
        >
          <Grid container flexDirection="column" className={classes.drawerCont}>
            <Grid item xs={12}>
              <img src={logo} alt="" />
            </Grid>
            <Grid item xs={12} className={classes.mainMenu}>
              {MainMenu}
            </Grid>
            {/* <Divider /> */}
            <Grid item xs={12}>
              {TopMenu}
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
      {/* <Grid container item justify="flex-end" xs={9}></Grid> */}
    </Grid>
  );
}
export default Header;
