import React, { useState } from "react";

const AuthContext = React.createContext([{}, () => {}]);

const AuthContextProvider = (props) => {
  const accountType = localStorage.getItem("accountType");
  const UserID = localStorage.getItem("UserId");
  const fName = localStorage.getItem("fName");
  const lName = localStorage.getItem("lName");
  const [state, setState] = useState({
    accType: accountType ? accountType : null,
    userID: UserID ? UserID : "0sj2kf03uw9d0o3j",
    fname: fName ? fName : null,
    lname: lName ? lName : null,
  });
  return (
    <AuthContext.Provider value={[state, setState]}>
      {props.children}
    </AuthContext.Provider>
  );
};

// const data =
export { AuthContext, AuthContextProvider };
