import React, { useState, useContext } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Card,
  CardContent,
} from "@material-ui/core";

import { AuthContext } from "../../Context/AuthContext";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import LoginInfo from "../../Assets/securities.json";
import LogoColor from "../../Assets/DylanLogo-color.png";
import Photo from "../../Assets/timothy-meinberg-bgONjl6y3bI-unsplash.jpg";
import ConsumerPhoto from "../../Assets/gautier-salles-HYxy22wEUX4-unsplash.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "left",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    maxWidth: "475px",
    backgroundColor: "rgba(255,255,255,.7)",
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
  error: {
    color: theme.palette.warning.main,
  },
}));

function PrLogin() {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useContext(AuthContext);
  console.log(state);
  // const [login] = useState(
  //   history.location.pathname.endsWith("producer") ? "producer" : "policyholder"
  // );
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setError("");
  };
  const handlePass = (event) => {
    setPass(event.target.value);
    setError("");
  };

  const submitForm = () => {
    // const loginType = history.location.pathname.endsWith("producer")
    //   ? "producer"
    //   : "policyholder";
    const isEmailValid = LoginInfo.find((item) => item.email === email);
    console.log(isEmailValid);
    const isPassValid = isEmailValid.password === pass ? true : false;
    let Path = "/login";
    if (isEmailValid && isPassValid) {
      if (isEmailValid.accType === "agent") {
        // console.log("submitted:", email, pass)
        // setState((state) => ({
        //   ...state,
        //   userID: isEmailValid.id,
        //   fname: isEmailValid.firstName,
        //   lname: isEmailValid.lastName,
        //   accType: isEmailValid.accType,
        // }));
        Path = "/producer/home";
        // history.push("/producer/home");
      } else if (isEmailValid.accType === "csr") {
        // setState((state) => ({
        //   ...state,
        //   userID: isEmailValid.id,
        //   fname: isEmailValid.firstName,
        //   lname: isEmailValid.lastName,
        //   accType: isEmailValid.accType,
        // }));
        Path = "/producer/home";
      } else if (isEmailValid.accType === "ph") {
        console.log(isEmailValid);
        // setState((state) => ({
        //   ...state,
        //   userID: isEmailValid.id,
        //   fname: isEmailValid.firstName,
        //   lname: isEmailValid.lastName,
        //   accType: isEmailValid.accType,
        // }));
        Path = "/policyholder/home";
      } else {
        setError("* Incorrect Email or Password");
      }
    } else {
      setError("* Incorrect Email or Password");
    }
    setState((state) => ({
      ...state,
      userID: isEmailValid.id,
      fname: isEmailValid.firstName,
      lname: isEmailValid.lastName,
      accType: isEmailValid.accType,
    }));
    localStorage.setItem("accountType", isEmailValid.accType);
    localStorage.setItem("UserId", isEmailValid.id);
    localStorage.setItem("fName", isEmailValid.firstName);
    localStorage.setItem("lName", isEmailValid.lastName);
    history.push(Path);
  };

  return (
    <Grid
      container
      className={classes.root}
      style={{
        backgroundImage: history.location.pathname.endsWith("producer")
          ? `url(${Photo})`
          : `url(${ConsumerPhoto})`,
      }}
    >
      <Grid item container justify="center" alignContent="center" xs={12}>
        <Card className={classes.paper}>
          <CardContent>
            <Button
              onClick={() => {
                history.push("/");
              }}
              variant="outlined"
            >
              home
            </Button>
            <Grid container justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <img src={LogoColor} alt="" style={{ maxWidth: "150px" }} />
                <Typography variant="h2" style={{ padding: "20px 0" }}>
                  {history.location.pathname.endsWith("producer")
                    ? "Producer Sign In"
                    : "Policyholder Sign In"}
                </Typography>
              </Grid>
            </Grid>

            <form noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                color="primary"
                label="Email"
                name="email"
                id="email"
                fullWidth
                error={error}
                onChange={handleEmail}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                color="primary"
                label="Password"
                type="password"
                name="pass"
                id="pass"
                fullWidth
                error={error}
                className={classes.TextField}
                onChange={handlePass}
                required
              />
              <Typography variant="body2" className={classes.error}>
                {error}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit}
                onClick={submitForm}
                fullWidth
              >
                {" "}
                Submit
              </Button>
            </form>

            <Grid container alignContent="center" justify="space-between">
              <Grid item>
                <Link href="#" variant="body2" align="center">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                {history.location.pathname.endsWith("producer") ? (
                  <Link component={RouterLink} to="/policyholder">
                    Policyholder Login
                  </Link>
                ) : (
                  <Link component={RouterLink} to="/producer">
                    Producer Login
                  </Link>
                )}
              </Grid>{" "}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default PrLogin;
