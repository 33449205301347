const sendMessage = (type, payload) => {
  console.log("Posting message:", type, payload);
  window.postMessage({ type, payload }, "*");
}

const launchBot = (
  launchEvent,
  payload
) => {
  let eventName = launchEvent === "GlobalLaunch" ? 
      "OPEN_GLOBAL_CHAT_WINDOW" :
      "OPEN_CHAT_WINDOW"

  //This is how I'm going to define this type without typescript
  let {
    userType,
    policyNumber,
    producerCode,
    firstName,
    lastName,
    mockBpServer,
    token,
    fyiTabUrl,
    trainingTabUrl,
    mockPolicyApi
  } = payload

  sendMessage(eventName, {
    userType,
    policyNumber,
    producerCode,
    firstName,
    lastName,
    mockBpServer,
    token,
    fyiTabUrl,
    trainingTabUrl,
    mockPolicyApi
  });
};

export const launchAgentFaq = (userType = "Agent") => {
  launchBot("GlobalLaunch", {
    userType,
    producerCode: 234987,
    firstName: "Agent",
    lastName: "Smith",
    mockBpServer: true,
    fyiTabUrl: "fyi.google.com",
    trainingTabUrl: "training.google.com",
    mockPolicyApi: true
  })
}

/**
@param {Agent | Insured | Internal | Web} userType The User type
@param {string} policyNumber The policy number, undefined triggers lookup
*/
export const launchFnol = (userType, policyNumber) => {
  launchBot("FNOL", {
    userType,
    policyNumber,
    firstName: "Theo",
    lastName: "User",
    mockBpServer: true
  })
}

/**
 * 
 * @param {string} policyNumber The policy number, undefined triggers lookup
 */
export const launchAgentFnol = (policyNumber, userType = "Agent") => {
  launchBot("FNOL", {
    userType,
    producerCode: 234987,
    policyNumber,
    firstName: "Agent",
    lastName: "Smith",
    mockBpServer: true,
    fyiTabUrl: "fyi.google.com",
    trainingTabUrl: "training.google.com",
    mockPolicyApi: true
  })
}