import React, { useEffect, useState, useContext } from "react";

// import { NavLink } from "react-router-dom";
import { Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Claims from "../../Assets/claims.json";

import PhTable from "../../Components/PhTable";

import PhHeader from "../../Components/Header/PhHeader";
import { AuthContext } from "../../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    "@media (min-width:600px)": {
      margin: theme.spacing(5, 10),
    },
    margin: theme.spacing(1, 2),
  },
}));

function PhClaims(props) {
  const classes = useStyles();
  const [claims, setClaims] = useState();

  const [state] = useContext(AuthContext);
  console.log(state);

  useEffect(() => {
    const filtClaims = Claims.filter((item) => {
      if (item.owner === state.userID) {
        console.log(item);
        return item;
      }

      return "";
    });
    setClaims(filtClaims);
    console.log(filtClaims);
  }, [setClaims, state.userID]);

  return (
    <PhHeader breadcrumb="claims">
      <Box className={classes.container}>
        {claims ? <PhTable data={claims} type="Claims" /> : null}
      </Box>
    </PhHeader>
  );
}

export default PhClaims;
