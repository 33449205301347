import React, { useEffect, useState, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
// import { NavLink } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { launchFnol } from "../../util/launchChatbot"
import Policies from "../../Assets/Policies.json";
import Claims from "../../Assets/claims.json";
import PhHeader from "../../Components/Header/PhHeader";
import TabPanel from "../../Components/TabPanel";
import PhTable from "../../Components/PhTable";

const useStyles = makeStyles((theme) => ({
  policyInfo: {
    marginTop: "40px",
  },
  info: {
    backgroundColor: theme.palette.primary.light,
    padding: "10px 20px",
    // maxWidth: "90%",
    color: "white",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    "& p, & h6": {
      alignSelf: "center",
      margin: "0px 6px 0 0",
    },
  },
  appbar: {
    marginTop: "10px",
  },
  policy: {
    width: "100%",
    textAlign: "center",
  },
  billing: {
    margin: theme.spacing(3, 0, 0, 0),
  },
  styledButton: {
    margin: theme.spacing(3, 0, 3, 3),
  },
  textButton: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  paymentTitle: {
    margin: theme.spacing(3, 0, 0, 0),
  },
  payTitleRow: {
    margin: theme.spacing(1, 0, 0, 0),
    padding: theme.spacing(1, 0),
    borderBottom: "solid 1px grey",
  },
  payRow: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1, 0),
    borderBottom: "solid 1px grey",
  },
}));

function PhPolicy() {
  const classes = useStyles();
  const params = useParams();
  const loca = useLocation();
  const [policy, setPolicy] = useState();
  const [claims, setClaims] = useState();
  const [value, setValue] = React.useState(
    loca.pathname.endsWith("billing") ? 0 : 1
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const filtered = Policies.find((item) => item.id === params.id);
    const filtClaims = Claims.filter((item) => {
      if (item.id === filtered.claims[0].id) {
        console.log(item);
        console.log(filtered);
        return item;
      }

      return "";
    });
    setPolicy(filtered);
    setClaims(filtClaims);
    console.log(filtClaims);
  }, [setPolicy, setClaims, params.id]);

  const date = useMemo(() => {
    if (!policy) {
      return "";
    }
    return `${policy.date}/${new Date().getFullYear()}`;
  }, [policy]);

  return (
    <PhHeader
      policy={policy ? policy : null}
      breadcrumb={value === 0 ? "BILLING" : "CLAIMS"}
    >
      {policy ? (
        <>
          <Box>
            <Button variant="outlined" className={classes.styledButton}>
              APPLICATION FORMS
            </Button>
            <Button variant="outlined" className={classes.styledButton}>
              Change Insurance Prefferences
            </Button>
            <Button 
              variant="outlined" 
              className={classes.styledButton}
              onClick={() => launchFnol("Insured", policy.policy)}
            >
              Submit A Claim 
            </Button>
            <Grid container className={classes.policyInfo}>
              <Grid
                item
                container
                // justify="center"
                alignContent="center"
                xs={2}
              >
                <Typography variant="body1" className={classes.policy}>
                  {policy.policy}
                </Typography>
                <Typography variant="h6" className={classes.policy}>
                  SUBMITTED
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Box className={classes.info}>
                  <Typography variant="h6">{policy.name}</Typography>
                  <Typography>Date: </Typography>
                  <Typography variant="h6">{date}</Typography>
                  <Typography>Address: </Typography>
                  <Typography variant="h6">
                    {policy.address.line1}, {policy.address.city}{" "}
                    {policy.address.state}, {policy.address.zip}
                  </Typography>
                  <Typography>County: </Typography>
                  <Typography variant="h6">{policy.address.county}</Typography>
                  <Typography>Product: </Typography>
                  <Typography variant="h6">{policy.product}</Typography>
                  <Typography>Company: </Typography>
                  <Typography variant="h6">{policy.company}</Typography>
                  <Typography>Co-Insured: </Typography>
                  <Typography variant="h6">{policy.coInsured}</Typography>
                  <Typography>Years Renewed: </Typography>
                  <Typography variant="h6">{policy.yearsRenewed}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={9}>
                <Box className={classes.appbar}>
                  <AppBar position="static" color="transparent">
                    <Tabs value={value} onChange={handleChange}>
                      <Tab label="Billing"></Tab>
                      <Tab label="Claims"></Tab>
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={1} dir="ltr">
                    <PhTable data={claims} type="Claims" />
                  </TabPanel>
                  <TabPanel value={value} index={0} dir="ltr">
                    <Grid container>
                      <Grid item xs={4} className={classes.billing}>
                        <Typography variant="h6">PAYMENT STATUS</Typography>
                        <Typography variant="h5">PAYMENT PENDING</Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.billing}>
                        <Typography variant="h6">BILL TYPE</Typography>
                        <Typography variant="h5">DIRECT BILL</Typography>
                      </Grid>
                      <Grid item xs={4} className={classes.billing}>
                        <Typography variant="h6">
                          {" "}
                          PAYMENT INFORMATION
                        </Typography>
                        <Typography variant="h5"> PREMIUM</Typography>
                        <Typography>Premium: ${policy.totalpremium}</Typography>
                        <Typography>Total Paid: ${policy.totalpaid}</Typography>
                        <Typography>
                          Remaining Balance: $
                          {policy.totalpremium - policy.totalpaid}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} className={classes.billing}>
                        <Typography variant="h6">PAYMENT METHOD</Typography>
                        <Typography variant="body1">
                          Policy is setup to accept one-time payments only
                        </Typography>
                        <Button
                          variant="outlined"
                          className={classes.textButton}
                        >
                          {" "}
                          CHANGE PREFERENCES
                        </Button>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}></Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          color="primary"
                          className={classes.paymentTitle}
                        >
                          Payment History
                        </Typography>

                        <Grid container>
                          <Grid
                            item
                            container
                            xs={12}
                            className={classes.payTitleRow}
                          >
                            <Grid item xs={1}>
                              <Typography variant="h6" color="primary">
                                Date
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="h6" color="primary">
                                Type
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="h6" color="primary">
                                Due Date
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography variant="h6" color="primary">
                                Premium
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="h6" color="primary">
                                Fee
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="h6" color="primary">
                                Details
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <Typography variant="h6" color="primary">
                                Invoice
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            className={classes.payRow}
                          >
                            <Grid item xs={1}>
                              N/A
                            </Grid>
                            <Grid item xs={2}>
                              Installment
                            </Grid>
                            <Grid item xs={2}>
                              11/15/2020
                            </Grid>
                            <Grid item xs={2}>
                              ${policy.totalpaid}
                            </Grid>
                            <Grid item xs={1}>
                              $0.00
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={1}></Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}
    </PhHeader>
  );
}

export default PhPolicy;
