import React from "react";

// import Page from "../../Components/Page/Page";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import {
  Grid,
  Typography,
  Button,
  Divider,
  Box,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../../Assets/DylanLogo.png";
import disaster from "../../Assets/disaster-image.jpg";
const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "100vh",
  },
  learnMore: {
    borderColor: theme.palette.secondary.light,
    color: theme.palette.secondary.light,
    fontSize: "1.2rem",
    fontWeight: "bold",
    // margin: theme.spacing(0, 0),
  },
  headline: {
    textAlign: "center",
    color: theme.palette.secondary.light,
    margin: theme.spacing(5, 0),
  },
  logoFeature: {
    margin: theme.spacing(10, 0),
  },
  disasterImage: {
    backgroundImage: `url(${disaster})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: "400px",
  },
  disaster: {
    maxWidth: "500px",
    margin: theme.spacing(10, 0),
  },
  disasterHeadline: {
    margin: theme.spacing(3, 0),
  },
  divider: {
    backgroundColor: theme.palette.primary.light,
    height: "3px",
    margin: theme.spacing(1, 0),
  },
  disasterButton: {
    margin: "40px 30%",
    width: "40%",
    textAlign: "center",
  },
}));

function Home() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.hero}>
        <Header home />
        <Grid item container sm={12}>
          <Grid item container justify="center" xs={12}>
            <Grid>
              <img src={logo} alt="logo" className={classes.logoFeature} />
            </Grid>
          </Grid>
          <Grid item container justify="center" xs={12}>
            <Grid item xs={11} sm={10} md={7} jusitfy="center">
              <Typography variant="h1" className={classes.headline}>
                We're your friendly experts in property insurance
              </Typography>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Grid item>
                <Button
                  variant="outlined"
                  align="center"
                  className={classes.learnMore}
                >
                  LEARN MORE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box className={classes.disasterImage}></Box>
        <Grid container justify="center">
          <Box className={classes.disaster}>
            <Container>
              <Typography
                variant="h2"
                align="center"
                className={classes.disasterHeadline}
              >
                Know what to do when disaster strikes?
              </Typography>
              <Divider className={classes.divider}></Divider>
              <Typography>
                On our website our chat bot will walk you through the claim
                process. Alternatively, you can call us at 1-888-123-4567.
              </Typography>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.disasterButton}
              >
                FILE A CLAIM
              </Button>
            </Container>
          </Box>
        </Grid>
      </Box>
      <Footer />
    </>
  );
}

export default Home;
