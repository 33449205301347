import React from "react";

import { NavLink } from "react-router-dom";
import {
  Grid,
  Button,
  InputBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: "100%",
    padding: "8px 16px 12px 16px",
    color: theme.palette.secondary.light,
    backgroundColor: theme.palette.primary.light,
  },
  container: {
    margin: theme.spacing(5, 10),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.light,
  },
  button: {
    textAlign: "right",
  },
}));

function PrTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // const tData = props.data.map((item) => {
  const tData = (
    rowsPerPage > 0
      ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : props.data
  ).map((item) => {
    return (
      <>
        {props.type === "Claims" ? (
          <TableRow key={item.id}>
            <TableCell> {item.id}</TableCell>
            <TableCell> {item.policy}</TableCell>
            <TableCell> {!item.closedDate ? "OPEN" : "CLOSED"} </TableCell>
            <TableCell>
              {!item.closedDate ? item.lossDate : item.closedDate}
            </TableCell>
            <TableCell className={classes.button}>
              {" "}
              <NavLink
                to={`/producer/claim/${item.id}`}
                className={classes.link}
              >
                <Button variant="outlined">View</Button>
              </NavLink>
            </TableCell>
          </TableRow>
        ) : null}
        {props.type === "Policies" ? (
          <TableRow key={item.id}>
            <TableCell> {item.policy}</TableCell>
            <TableCell> {item.name}</TableCell>
            <TableCell> {item.product}</TableCell>
            <TableCell className={classes.button}>
              {" "}
              <NavLink
                to={`/producer/policy/${item.policy}`}
                className={classes.link}
              >
                <Button variant="outlined" color="primary">
                  View
                </Button>
              </NavLink>
            </TableCell>
          </TableRow>
        ) : null}
      </>
    );
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Grid container justify="flex-end">
        <Grid item>
          <InputBase
            className={classes.input}
            placeholder={
              props.type === "Claims" ? "Search Claims" : "Search Policies"
            }
            inputProps={
              props.type === "Claims"
                ? { "aria-label": "search claims" }
                : { "aria-label": "search policies" }
            }
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {props.type === "Claims" ? (
                <>
                  <TableCell>Claim #</TableCell>
                  <TableCell>Policy #</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell className={classes.button}>View</TableCell>
                </>
              ) : null}
              {props.type === "Policies" ? (
                <>
                  <TableCell>Policy</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell className={classes.button}>View</TableCell>
                </>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>{tData}</TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={props.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              // nextIconButtonProps={() => setPage(page + 1)}
              // backIconButtonProps={() => setPage(page - 1)}
              // ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

export default PrTable;
